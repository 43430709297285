export default {
  homeTabs: {
    home: 'Home',
    aboutUs: 'About Us',
    newsNotice: 'News Reel',
    outcome: 'Cooperation Projects',
    report: 'Declaration Platform',
  },
  documentTitle: {
    home: 'Asian Cultural Heritage Conservation Program',
    aboutUs: 'About Us—Asian Cultural Heritage Conservation Program',
    newsNotice: 'News Reel—Asian Cultural Heritage Conservation Program',
    outcome: 'Cooperation Projects—Asian Cultural Heritage Conservation Program',
    report: 'Declaration Platform—Asian Cultural Heritage Conservation Program',
    manage: 'Project Management—Asian Cultural Heritage Conservation Program',
  },
  language: {
    zh: '简体中文',
    en: 'English',
  },
  loginRegister: {
    login: 'Login',
    register: 'Register',
    password: 'Password',
    forgetPassword: 'Forgot Password',
    phonePlaceholder: 'Please Enter Mobile Number',
    areaCode: 'Area Code',
    passwordPlaceholder: 'Please Enter Password',
    loginMethod: 'Login Method',
    loginAccount: 'Account Login',
    loginSms: 'SMS Login',
    invitePlaceholder: 'Please Enter Secret Invitation Key',
    codePlaceholder: 'Enter Verification Code',
    queryCode: 'Obtain Verification Code',
    sendSuccess: 'Send Success',
    queryCodeAgainWithS: 's Obtain Again',
    queryCodeAgain: 'Obtain Again',
    userRegister: 'User Registration',
    newUserRegister: 'New User Registration',
    existAccount: 'Existing Account?',
    loginNow: 'Log in Now',
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    submit: 'Submit',
    newPasswordPlaceholder: 'Please Enter New Password',
    confirmPasswordPlaceholder: 'Please Enter Confirm Password',
  },
  loginAgreement: {
    readAndAgree: 'I Have Read And Agree',
    agreement: 'User Agreement',
    privacy: 'Privacy Terms',
    and: 'And',
    checkedAgreement: 'Please Check Agreement',
  },
  status: {
    registerSuccess: 'Register Success',
    resetSuccess: 'Reset Success',
    changeSuccess: 'Modify Success',
    addSuccess: 'Add Success',
    deleteSuccess: 'Delete Success',
    saveSuccess: 'Save Success',
    submitSuccess: 'Submit Success',
    success: 'Success',
  },
  home: {
    report: 'I Want to Declare',
    moreAchievements: 'More Cooperation Results',
    contactPhone: "Contact No.",
    reserved: 'All Rights Reserved, Asian Cultural Heritage Conservation Program',
  },
  aboutUs: {
    aboutUs: 'About Us',
    tabTitleOne: 'Secretariat of the Alliance for Cultural Heritage in Asia',
    tabTitleTwo: 'Alliance for Cultural Heritage in Asia',
    tabTitleThree: 'Introduction to the Asian Fund for Cultural Heritage Conservation',
    tabTitleFour: 'Contact Us',
  },
  notices: {
    newsNotice: 'News Reel',
    lastNews: 'Latest News',
    notice: 'Notices'
  },
  outcome: {
    achievements: 'Cooperation Projects',
    tabTitleOne: 'Joint Archaeology',
    tabTitleTwo: 'Cultural Relics Restoration',
    tabTitleThree: 'World Heritage Sites',
    tabTitleFour: 'Exhibition',
    tabTitleFive: 'Personnel Training',
    tabTitleSix: 'Academic Research',
    tabTitleSeven: 'Publicity',
    tabTitleEight: 'Others',
  },
  report: {
    platform: 'Declaration Platform',
    guide: 'Reporting Guidelines',
    declare: 'Project Declaration',
    step1: 'Step 1',
    step2: 'Step 2',
    confirmDeclarationInfo: 'Confirm Declaration Information',
    inputProjectInfo: 'Input Project Information',
    basicInfo: 'Basic Overview',
    projectInfo: 'Project Overview',
  },
  manage: {
    basicData: 'Basic Data',
    accountInfo: 'Account Info',
    accountManage: 'Account Management',
    basicAccountEdit: 'Basic Account Modification',
    subAccountManage: 'Sub Account Management',
    loginPhone: 'Login Mobile Number',
    changePhone: 'Change Mobile Number',
    numbering: 'Numbering',
    contactPhone: 'Contact Number',
    date: 'Date',
    operate: 'Operate',
    edit: 'Modify',
    close: 'Close',
    open: 'Open',
    add: 'Add',
    cancel: 'Cancel',
    commit: 'Submit',
    changePassword: 'Change Password',
    loginOut: 'Logout',
    name: 'Name',
    namePlaceholder: 'Please Enter Name',
    addReportPerson: 'New Project Applicants',
    editReportPerson: 'Modify Project Applicants',
    initPasswordPlaceholder: 'Please Enter Initial Password',
  },
  manageHome: {
    certifyManage: 'Certification Management',
    projectManage: 'Project Management',
    resourceDownload: 'Resource Downloads',
  },
  projectManage: {
    report: 'Project Declaration',
    list: 'Project List',
    implement: 'Implementation Checklist',
    achievements: 'Results List',
    keywordSearch: 'Keyword Search',
  },
  selectOptions: {
    all: 'All',
    drafted: 'Drafted',
    unReviewed: 'To Be Reviewed',
    unDeal: 'Handling',
    passed: 'Passed',
    failed: 'Failed',
    unImplement: 'Under Implementation',
    finishUnCheck: 'Closing to Be Reviewed',
    finishUnDeal: 'Closing to Be Handled',
    finished: 'Closed Items',
    finishReviewFail: 'Close Review Failed',
    extensionUnReview: 'Extension Under Review',
    extensionUnReal: 'Handling Deferred',
    extension: 'Extension',
    extensionReviewFail: 'Extension Review Failed',
    select: 'Select',
    privateEnterprise: 'Private Enterprises',
    socialServiceEnterprise: 'Social Service Enterprises',
    socialOrgan: 'Social Groups',
  },
  tableHeader: {
    No: "No.",
    projectName: 'Project Name',
    projectType: 'Project Type',
    reportDate: 'Declaration Date',
    currentStatus: 'Current Status',
    reviewDetail: 'Review Details',
    reportDetail: 'Declaration Details',
    continueReport: 'Continue Declaration',
    delete: 'Delete',
    reSubmit: 'Resubmit',
    resultStage: 'Result Stage',
    startEndTime: 'Start and End Time',
    productIntroduce: 'Project Introduce',
    backList: 'Back',
  },
  operate: {
    ok: 'OK',
    settleDeclaration: 'Settle Declaration',
    extendDeclaration: 'Extend Declaration',
    view: 'View',
    settleDetail: 'Settle Detail',
  },
  details: {
    organFullName: 'Organization Full Name',
    organAddress: 'Organization Address',
    organDetailAddress: 'Detail Address',
    organEmail: 'Institutional Contact Email',
    email: 'Email',
    organType: 'Organization Type',
    legalPersonName: 'Legal Person Name',
    organPersonName: 'Institutional representative',
    legalPersonID: 'Legal Person ID Number',
    legalPersonEmail: 'Institutional representative email',
    personID: 'ID Number',
    legalPersonIDPic: 'Legal Person ID Card',
    personIDPic: 'ID Card',
    creditCode: 'Unified Social Credit Code',
    registerCertify: 'Legal Person Registration Certificate',
    businessScope: 'Business Scope',
    organIntro: 'Organization Introduction',
    projectStartEndDate: 'Project Start And End Date',
    totalScale: 'Total Scale（__0k Yuan）',
    supplyScale: 'Subsidy Application（__0k Yuan）',
    selfScale: 'Self-Raised Funds（__0k Yuan）',
    projectLeader: 'Project Leader',
    leaderPhone: 'Leader Phone Number',
    projectApplication: 'Project Application',
    stampedDocument: 'Stamped Documents',
    otherResource: 'Other Resources',
    goBack: 'Return',
    contactAddress: 'Contact Address',
    personalProfile: 'Personal Profile',
    subsidyApplication: 'Subsidy Application',
    yes: 'YES',
    no: 'NO',
    uploadAttachments: 'Upload Attachments',
    uploadTips: 'Please add the following attachments according to the template. Please download the attachment template first',
    downloadTemplate: 'Download Application Template',
    uploadApplication: 'Upload the Project Application（Word File）',
    requireFile: 'Require Word、PDF File',
    uploadApplication2: 'Upload the Project Application（Stamped Documents）',
    requireFile2: 'Require Jpg、Jpeg、Png、PDF File',
    requireFile3: 'Require Word Jpg、Jpeg、Png、PDF File',
    requireFile4: 'Upload photo and video compression package',
    saveDrafts: 'Save Drafts',
    selectSupply: 'Select Whether Supply',
  },
  certification: {
    personCertify: 'Personal Certification',
    placeholderEmail: 'Please Enter Contact Email',
    placeholderID: 'Please Enter ID Number',
    placeholderIDCard: 'Please Upload ID Card',
    placeholderIDCardBoth: 'Upload The Front And Back Pictures of Your ID Card',
    placeholderPersonalProfile: 'Please Enter Personal Profile',
    uploadIdCard: 'Upload ID Card',
    selectCountry: 'Select Country',
    selectProvince: 'Select Province',
    selectCity: 'Select City',
    placeholderAddress: 'Please Enter Address',
    auditInfo: 'Audit Information',
    previous: 'Previous',
    cancel: 'Cancel',
    submit: 'Submit',
    next: 'Next',
    organCertify: 'Agency Certification Info',
    placeOrganFullName: 'Please Enter Organization Full Name',
    placeOrganEmail: 'Please Enter Institutional Contact Email',
    contact: 'Contact',
    organContact: 'Institutional Contact Person',
    placeholderContact: 'Please Enter Contact',
    placeLegalPersonName: 'Please Enter Legal Person Name',
    placeLegalPersonID: 'Please Enter Legal Person ID Number',
    placeLegalPersonEmail: 'Please Enter Institutional representative email',
    placeCreditCode: 'Please Enter Unified Social Credit Code',
    placeOrganIntro: 'Please Enter Organization Introduction',
    placeOrganType: 'Select Organization Type',
    placeRegisterCertify: 'Upload Legal Person Registration Certificate',
    placeBusinessScope: 'Please Enter Business Scope',
    selectAuthType: 'Select Authentication Type',
    organAuth: 'Agency Certification',
    personalAuth: 'Personal Certification',
  },
  achievements: {
    newResults: 'View New Results',
    submitStageResult: 'Submit Project Stage Result',
    selectProject: 'Select Project',
    enterProjectStage: 'Enter Project Stage',
    stageStartEndTime: 'Stage Start and End Time',
    startTime: 'Start Time',
    endTime: 'End Time',
    to: 'to',
    simplePic: 'Thumbnail',
    introStageResult: 'Introduction to Stage Result',
    placeThumbnail: 'Upload The Thumbnail of The Stage Results',
    resultDesc: 'Results Description',
    placeResultDesc: 'Enter Results Description With Less Than 100 Words',
    placeResultDesc2: 'Enter Results Description',
  },
  implement: {
    projectStartEndTime: 'Project Start and End Time',
    selectDate: 'Select Date',
    upload: 'Upload',
  },
  download: {
    checkAll: 'Check All',
    download: 'Download',
    downloadTips: 'Please Select File',
  },
  uploadTips: {
    personCardTips: 'The face and ID card Info must be clear without any obstructions, and the hands must be completely exposed',
    sizeLimit: 'Cannot Exceed 2MB',
  },
  interaction: {
    notice: 'Notice',
    cancel: 'Cancel',
    confirm: 'Confirm',
    exitTips: 'Are You Sure Exit?',
    certifyTips: 'Please Certify First Before Using This Function',
    certifyProcessTips: 'When Certifying, Items Can Be Declared After Passing The Certification Review',
    reviewFailedTips: 'Review Failed. Please Modify And Resubmit The Materials',
    closeTips: 'Are You Sure You Want to Close?',
    openTips: 'Are You Sure You Want to Open?',
    deleteTips: 'Are You Sure You Want to Delete The Current Item?',
    personalProfile: 'Ask The Person in Charge to Introduce The Work Overview of The Organization',
    cardBothTips: 'Upload The Front And Back Pictures of Your ID Card，Supported Extensions: JPG And PNG Formats，Size: Less Than 2MB',
    organProfile: 'Input The Business Scenario Description According to The Registration Type，Must Be Consistent with The Information on The Legal Person Registration Certificate',
    uploadBizLicenseTips: 'Upload The Business License，Supported Extensions: JPG And PNG Formats，Size: Less Than 2MB',
    phoneAuth: 'Phone Number Authentication',
    extensionTill: 'Apply for Extension Till',
    extensionReason: 'Reason for Extension',
    uploadExtension: 'Upload Extension Application Materials',
    uploadExtensionSupport: 'Supports Word, JPG, JPEG, PNG, And PDF Formats',
    uploadExtensionTips: 'Affix An Official Seal to The Above Documents for Organization Applications',
    projectCloseTime: 'Project Closing Time',
    uploadCloseReport: 'Upload The Closing Report',
    uploadCloseReportSeal: 'Upload The Closing Report (With Official Seal)',
    uploadResultReport: 'Upload The Results Report',
    uploadOther: 'Upload Other Supplementary Materials',
    setPassword: 'Set Password',
    setNewPhone: 'Set New Phone Number',
    templateFile: 'Template File',
    uploadImgTips: 'Please Select The Correct Picture Format',
  },
  routeTitle: {
    loginRegister: 'Login/Register',
    chooseCertification: 'Choose Authentication',
    reportAgreement: 'Declaration Agreement',
    managerCenter: 'Manager Center',
  },
  matter: {
    amount: 'Please enter the amount in the correct format'
  }
}