const HEADER_TAB_INDEX = 'header_tab_index'

const NOTICE_TAB_INDEX = 'notice_tab_index'

const OUTCOME_TAB_INDEX = 'outcome_tab_index'

const REPORT_TAB_INDEX = 'report_tab_index'

const ABOUTUS_TAB_INDEX = 'aboutus_tab_index'

const USER_INFO = 'user_info'

const MANAGE_TAB_INDEX = 'manage_tab_index'

const PROJECT_TAB_INDEX = 'project_tab_index'

const TOKEN_AUTH = 'token_auth'

const PROJECT_ID_EDIT = 'project_id_edit'

const LOCALE_LANGUAGE = 'locale_language'

export {
  HEADER_TAB_INDEX,
  NOTICE_TAB_INDEX,
  OUTCOME_TAB_INDEX,
  REPORT_TAB_INDEX,
  ABOUTUS_TAB_INDEX,
  USER_INFO,
  MANAGE_TAB_INDEX,
  PROJECT_TAB_INDEX,
  TOKEN_AUTH,
  PROJECT_ID_EDIT,
  LOCALE_LANGUAGE,
}
