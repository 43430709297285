<template>
  <div class="index-container">
    <page-header @tabClick="tabClick"></page-header>
    <router-view style="margin-top: 61px;flex: 1"></router-view>
    <page-footer></page-footer>
  </div>
</template>

<script setup>
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import router from "@/router";
import { useStore } from "vuex";

const store = useStore();

const routeList = [
  "/index/home",
  "/index/aboutUs",
  "/index/notice",
  "/index/outcome",
  "/index/report",
];

const tabClick = (tabIndex) => {
  //切换主tab时，将动态资讯页面中子路由恢复选中第一个
  if (tabIndex == 1) {
    store.commit("setAboutusTabIndex", 0);
  }
  if (tabIndex == 2) {
    store.commit("setNoticeTabIndex", 0);
  }
  if (tabIndex == 3) {
    store.commit("setOutcomeTabIndex", 0);
  }
  if (tabIndex == 4) {
    store.commit("setReportTabIndex", 0);
  }
  router.push({
    path: routeList[tabIndex],
  });
};
</script>

<style scoped lang="less">
.index-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 1440px;
  background: white;
}
</style>
