import { createI18n } from "vue-i18n";
import zh from "./lang/zh-cn";
import en from "./lang/en";
import {getLocaleLanguage} from "@/utils/storage-utils";

const messages = {
  zh,
  en
}

//const language = (navigator.language || 'en').toLocaleLowerCase(); // 这是获取浏览器的语言
const i18n = createI18n({
  legacy: false,
  locale: getLocaleLanguage() == 'zh_CN' ? 'zh' : 'en',
  fallbackLocale: 'zh',
  globalInjection: true,
  messages,
})

export default i18n
