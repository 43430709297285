export default {
  homeTabs: {
    home: '首页',
    aboutUs: '关于我们',
    newsNotice: '动态资讯',
    outcome: '合作成果',
    report: '申报平台',
  },
  documentTitle: {
    home: '亚洲文化遗产保护行动官网',
    aboutUs: '关于我们-亚洲文化遗产保护行动官网',
    newsNotice: '动态资讯-亚洲文化遗产保护行动官网',
    outcome: '合作成果-亚洲文化遗产保护行动官网',
    report: '申报指南-亚洲文化遗产保护行动官网',
    manage: '亚洲文化遗产保护行动申报平台',
  },
  language: {
    zh: '简体中文',
    en: 'English',
  },
  loginRegister: {
    login: '登录',
    register: '注册',
    password: '密码',
    forgetPassword: '忘记密码',
    phonePlaceholder: '请输入手机号',
    areaCode: '区号',
    passwordPlaceholder: '请输入密码',
    loginMethod: '登录方式',
    loginAccount: '账号登录',
    loginSms: '短信登录',
    invitePlaceholder: '请输入邀请秘钥',
    codePlaceholder: '请输入验证码',
    queryCode: '获取验证码',
    sendSuccess: '发送成功',
    queryCodeAgainWithS: 's后重试',
    queryCodeAgain: '重新获取',
    userRegister: '用户注册',
    newUserRegister: '新用户注册',
    existAccount: '已有账号?',
    loginNow: '立即登录',
    resetPassword: '重置密码',
    newPassword: '新密码',
    confirmPassword: '确认密码',
    submit: '提交',
    newPasswordPlaceholder: '请输入新密码',
    confirmPasswordPlaceholder: '请输入确认密码',
  },
  loginAgreement: {
    readAndAgree: '我已阅读并同意',
    agreement: '用户协议',
    privacy: '隐私条款',
    and: '和',
    checkedAgreement: '请勾选协议',
  },
  status: {
    registerSuccess: '注册成功',
    resetSuccess: '重置成功',
    changeSuccess: '修改成功',
    addSuccess: '新增成功',
    deleteSuccess: '删除成功',
    saveSuccess: '保存成功',
    submitSuccess: '提交成功',
    success: '操作成功',
  },
  home: {
    report: '我要申报',
    moreAchievements: '更多合作成果',
    contactPhone: "联系电话",
    reserved: '版权所有 亚洲文化遗产保护行动',
  },
  aboutUs: {
    aboutUs: '关于我们',
    tabTitleOne: '亚洲文化遗产保护联盟秘书处介绍',
    tabTitleTwo: '亚洲文化遗产保护联盟',
    tabTitleThree: '亚洲文化遗产保护基金简介',
    tabTitleFour: '联系我们',
  },
  notices: {
    newsNotice: '动态资讯',
    lastNews: '最新资讯',
    notice: '通知公告'
  },
  outcome: {
    achievements: '合作成果',
    tabTitleOne: '联合考古',
    tabTitleTwo: '文物修复',
    tabTitleThree: '世界遗产',
    tabTitleFour: '展览展示',
    tabTitleFive: '人才培养',
    tabTitleSix: '学术研究',
    tabTitleSeven: '公众宣传',
    tabTitleEight: '其他',
  },
  report: {
    platform: '申报平台',
    guide: '申报指南',
    declare: '项目申报',
    step1: '第一步',
    step2: '第二步',
    confirmDeclarationInfo: '确认申报信息',
    inputProjectInfo: '填写项目信息',
    basicInfo: '基本概况',
    projectInfo: '项目概况',
  },
  manage: {
    basicData: '基本资料',
    accountInfo: '账号基本信息',
    accountManage: '账号管理',
    basicAccountEdit: '基础账号修改',
    subAccountManage: '子账号管理',
    loginPhone: '登录手机号',
    changePhone: '修改手机号',
    numbering: '编号',
    contactPhone: '联系电话',
    date: '日期',
    operate: '操作',
    edit: '修改',
    close: '关闭',
    open: '打开',
    add: '添加',
    cancel: '取消',
    commit: '确定',
    changePassword: '修改密码',
    loginOut: '退出登录',
    name: '姓名',
    namePlaceholder: '请输入姓名',
    addReportPerson: '新增项目申报人',
    editReportPerson: '修改项目申报人',
    initPasswordPlaceholder: '请输入初始密码',
  },
  manageHome: {
    certifyManage: '认证管理',
    projectManage: '项目管理',
    resourceDownload: '资料下载',
  },
  projectManage: {
    report: '项目申报',
    list: '项目清单',
    implement: '实施清单',
    achievements: '成果列表',
    keywordSearch: '请输入关键字',
  },
  selectOptions: {
    all: '全部',
    drafted: '草稿箱',
    unReviewed: '待审核',
    unDeal: '待办',
    passed: '已通过',
    failed: '未通过',
    unImplement: '实施中',
    finishUnCheck: '结项待审核',
    finishUnDeal: '结项待办',
    finished: '已结项',
    finishReviewFail: '结项审核未通过',
    extensionUnReview: '延期待审核',
    extensionUnReal: '延期待办',
    extension: '已延期',
    extensionReviewFail: '延期审核未通过',
    select: '请选择',
    privateEnterprise: '民办企业',
    socialServiceEnterprise: '社会服务企业',
    socialOrgan: '社会团体',
  },
  tableHeader: {
    No: "编号",
    projectName: '项目名称',
    projectType: '项目类型',
    reportDate: '申报日期',
    currentStatus: '当前状态',
    reviewDetail: '审查详情',
    reportDetail: '申报详情',
    continueReport: '继续申报',
    delete: '删除',
    reSubmit: '重新提交',
    resultStage: '成果阶段',
    startEndTime: '起止时间',
    productIntroduce: '项目介绍',
    backList: '返回列表',
  },
  operate: {
    ok: '确定',
    settleDeclaration: '申报结项',
    extendDeclaration: '申请延期',
    view: '查看',
    settleDetail: '结项详情',
  },
  details: {
    organFullName: '机构全称',
    organAddress: '机构地址',
    organDetailAddress: '详细地址',
    organEmail: '联系人邮箱',
    email: '邮箱',
    organType: '机构性质',
    legalPersonName: '法人姓名',
    organPersonName: '机构法人',
    legalPersonID: '法人身份证号',
    legalPersonEmail: '法人邮箱',
    personID: '身份证号码',
    legalPersonIDPic: '法人身份证',
    personIDPic: '身份证',
    creditCode: '统一社会信用代码',
    registerCertify: '法人登记证',
    businessScope: '业务范围',
    organIntro: '机构介绍',
    projectStartEndDate: '项目启动结束日期',
    totalScale: '总规模（万元）',
    supplyScale: '申请资助（万元）',
    selfScale: '自筹资金（万元）',
    projectLeader: '项目负责人',
    leaderPhone: '负责人电话',
    projectApplication: '项目申请书',
    stampedDocument: '项目申请书（盖章文件）',
    otherResource: '其他资料',
    goBack: '返回',
    contactAddress: '联系地址',
    personalProfile: '个人简介',
    subsidyApplication: '申请资助',
    yes: '是',
    no: '否',
    uploadAttachments: '上传附件',
    uploadTips: '以下附件内容请按模板填写，请先下载附件模板',
    downloadTemplate: '下载申请书模板',
    uploadApplication: '上传项目申请书（word文件）',
    requireFile: '支持word、pdf格式',
    uploadApplication2: '上传项目申请书（盖章文件）',
    requireFile2: '支持jpg、jpeg、png、pdf格式',
    requireFile3: '支持word jpg、jpeg、png、pdf格式',
    requireFile4: '照片视频等附件压缩包',
    saveDrafts: '保存草稿',
    selectSupply: '请选择是否需要资助',
  },
  certification: {
    personCertify: '个人信息认证',
    placeholderEmail: '请输入联系邮箱',
    placeholderID: '请输入身份证号码',
    placeholderIDCard: '请上传身份证',
    placeholderIDCardBoth: '请上传身份证正反面照片',
    placeholderPersonalProfile: '请输入个人介绍',
    uploadIdCard: '上传身份证',
    selectCountry: '选择国家',
    selectProvince: '选择省',
    selectCity: '选择市',
    placeholderAddress: '请输入详细地址',
    auditInfo: '审核信息',
    previous: '上一步',
    cancel: '取消',
    submit: '提交',
    next: '下一步',
    organCertify: '机构信息认证',
    placeOrganFullName: '请输入机构全称',
    placeOrganEmail: '请输入联系人邮箱',
    contact: '联系人',
    organContact: '机构联系人',
    placeholderContact: '请输入联系人',
    placeLegalPersonName: '请输入法人姓名',
    placeLegalPersonID: '请输入法人身份证号',
    placeLegalPersonEmail: '请输入法人邮箱',
    placeCreditCode: '请输入统一社会信用代码',
    placeOrganIntro: '请描述机构的概况',
    placeOrganType: '请选择机构性质',
    placeRegisterCertify: '法人登记证',
    placeBusinessScope: '请输入业务范围',
    selectAuthType: '选择认证类型',
    organAuth: '机构认证',
    personalAuth: '个人认证',
  },
  achievements: {
    newResults: '新建成果展示',
    submitStageResult: '提交项目阶段成果',
    selectProject: '选择项目',
    enterProjectStage: '输入项目阶段',
    stageStartEndTime: '阶段起止时间',
    startTime: '开始时间',
    endTime: '结束时间',
    to: '至',
    simplePic: '缩略图',
    introStageResult: '阶段成果介绍',
    placeThumbnail: '请上传阶段成果缩略图',
    resultDesc: '成果描述',
    placeResultDesc: '请输入100字以内成果描述',
    placeResultDesc2: '请输入成果描述',
  },
  implement: {
    projectStartEndTime: '项目起止时间',
    selectDate: '选择日期',
    upload: '上传',
  },
  download: {
    checkAll: '全选',
    download: '下载',
    downloadTips: '请选择下载文件',
  },
  uploadTips: {
    personCardTips: '脸部清晰无遮挡，身份证信息清晰无遮挡，完全露出双手',
    sizeLimit: '图片不能大于2MB',
  },
  interaction: {
    notice: '提示',
    cancel: '取消',
    confirm: '确定',
    exitTips: '确定要退出?',
    certifyTips: '请认证完成后再使用该功能',
    certifyProcessTips: '认证审核中，审核通过后可申报项目',
    reviewFailedTips: '审核失败，请修改资料后重新提交',
    closeTips: '确定要关闭吗',
    openTips: '确定要打开吗',
    deleteTips: '确定要删除当前项目吗',
    personalProfile: '请执行负责人介绍在机构的工作概况',
    cardBothTips: '上传身份证正面和背面图片，支持扩展名：jpg、png格式，大小：2M以内',
    organProfile: '根据注册类型填写业务场景描述，和法人登记证上信息保持一致',
    uploadBizLicenseTips: '营业执照或法人登记证，支持扩展名：jpg、png格式，大小2M以内',
    phoneAuth: '手机号身份验证',
    extensionTill: '申请延期至',
    extensionReason: '延期原因',
    uploadExtension: '上传延期申请资料',
    uploadExtensionSupport: '支持word、jpg、jpeg、png、pdf格式',
    uploadExtensionTips: '机构申请上述文件需加盖公章',
    projectCloseTime: '项目完成时间',
    uploadCloseReport: '上传结项报告',
    uploadCloseReportSeal: '上传结项报告（加盖公章）',
    uploadResultReport: '上传成果报告',
    uploadOther: '上传其他补充资料',
    setPassword: '设置密码',
    setNewPhone: '设置新手机号',
    templateFile: '模版文件',
    uploadImgTips: '请选择正确的图片格式',
  },
  routeTitle: {
    loginRegister: '登录注册',
    chooseCertification: '认证选择',
    reportAgreement: '申报协议',
    managerCenter: '管理中心',
  },
  matter: {
    amount: '请输入正确格式的金额'
  }
}