<template>
  <div class="header-container">
    <img class="header-log" src="@/assets/header/header_logo_new.png" />
    <div class="header-right">
      <div class="tabs-container">
        <template v-for="(item, index) in tabs">
          <span
            v-if="item.children.length <= 0"
            class="tab-item"
            :class="[activeName == item.name ? 'tab-item-active' : '']"
            @click="handleClick(index)"
            >{{ item.name }}</span
          >
          <el-dropdown v-else @command="menuCommand">
            <div style="line-height: 62px">
              <span
                class="tab-item"
                :class="[activeName == item.name ? 'tab-item-active' : '']"
                @click="handleClick(index)"
              >
                {{ item.name }}
              </span>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <template v-for="(menu, menuIndex) in item.children">
                  <el-dropdown-item :command="index + ',' + menu.path + ',' + menuIndex">
                    {{ menu.name }}
                  </el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </div>
      <div class="header-search" style="display: none">
        <img class="search-img" src="@/assets/header/search.png" />
      </div>
      <div v-if="!token" class="header-user">
        <span @click="userLogin">{{
          $t("loginRegister.login") + "/" + $t("loginRegister.register")
        }}</span>
      </div>
      <div v-else class="header-login-user" @click="userLogin">
        <img src="@/assets/login/user_avatar.png" />
      </div>
      <el-dropdown @visible-change="dropChange" @command="dropCommand">
        <div class="header-language">
          <span>{{ localeLanguage }}</span>
          <img :src="getDropIcon" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="zh_CN">{{ $t("language.zh") }}</el-dropdown-item>
            <el-dropdown-item command="en_US">{{ $t("language.en") }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import { computed, ref, defineEmits, watch, reactive } from "vue";
import { useStore } from "vuex";
import {
  getLocaleLanguage,
  getToken,
  getUserInfo,
  setLocaleLanguage,
} from "@/utils/storage-utils";
import { canUseManage, hasCertify } from "@/utils/bussiness-utils";
import i18n from "@/i18n";
import { ElMessageBox } from "element-plus";
import { openWindow } from "@/utils/user-utils";

const t = i18n.global.t;

const store = useStore();

//监听路由变化，点击页面非导航区域路由跳转时，使对应导航高亮
watch(
  () => router.currentRoute.value,
  (value) => {
    activeName.value = value.meta.title;
    store.commit(
      "setHeaderTabIndex",
      tabs.findIndex((tab) => tab.name === value.meta.title)
    );
    if (!value?.fullPath?.includes("/index/manage")) {
      store.commit("setManageTabIndex", 0);
    }
  }
);

watch(
  () => store.state.token,
  (newValue) => {
    token.value = newValue;
  }
);

const emit = defineEmits(["tabClick"]);

const tabs = reactive([
  {
    name: t("homeTabs.home"),
    children: [],
  },
  /* {
    name: t("homeTabs.aboutUs"),
    children: [
      {
        name: t('aboutUs.tabTitleOne'),
        path: '/about/xdjs',
      },
      {
        name: t('aboutUs.tabTitleTwo'),
        path: '/about/lmj'
      },
      {
        name: t('aboutUs.tabTitleThree'),
        path: '/about/jijin'
      },
      {
        name: t('aboutUs.tabTitleFour'),
        path: '/about/contact'
      }
    ],
  },
  {
    name: t("homeTabs.newsNotice"),
    children: [
      {
        name: t('notices.lastNews'),
        path: '/index/notice/new',
      },
      {
        name: t('notices.notice'),
        path: '/index/notice/inform'
      }
    ],
  },
  {
    name: t("homeTabs.outcome"),
    children: [
      {
        name: t('outcome.tabTitleOne'),
        path: '/index/outcome/content',
      },
      {
        name: t('outcome.tabTitleTwo'),
        path: '/index/outcome/content',
      },
      {
        name: t('outcome.tabTitleThree'),
        path: '/index/outcome/content',
      },
      {
        name: t('outcome.tabTitleFour'),
        path: '/index/outcome/content',
      },
      {
        name: t('outcome.tabTitleFive'),
        path: '/index/outcome/content',
      },
      {
        name: t('outcome.tabTitleSix'),
        path: '/index/outcome/content',
      },
      {
        name: t('outcome.tabTitleSeven'),
        path: '/index/outcome/content',
      },
      {
        name: t('outcome.tabTitleEight'),
        path: '/index/outcome/content',
      }
    ],
  }, */
  {
    name: t("homeTabs.report"),
    children: [
      {
        name: t("report.guide"),
        path: "/index/report/guide",
      },
      {
        name: t("report.declare"),
        path: "",
      },
    ],
  },
]);

const activeName = ref(tabs[store.state.headerTabIndex]?.name); //标记首个选中的tab标签

let dropShow = ref(false);

let token = ref(getToken());

const localeLanguage = ref(
  getLocaleLanguage() == "zh_CN" ? t("language.zh") : t("language.en")
);

const getDropIcon = computed(() => {
  if (dropShow.value) {
    return require("@/assets/header/arrow-up.png");
  } else {
    return require("@/assets/header/arrow-down.png");
  }
});

const handleClick = (tabIndex) => {
  const currentIndex = tabIndex;
  activeName.value = tabs[currentIndex]?.name;
  emit("tabClick", currentIndex);
  store.commit("setHeaderTabIndex", currentIndex);
};

const dropChange = (showDrop) => {
  dropShow.value = showDrop;
};

const dropCommand = (value) => {
  localeLanguage.value = value == "zh_CN" ? t("language.zh") : t("language.en");
  setLocaleLanguage(value);
  router.go(0);
};

const menuCommand = (command) => {
  const splitResult = command.split(",");
  const tabIndex = splitResult[0];
  const path = splitResult[1];
  const menuIndex = splitResult[2];
  if (tabIndex == 1) {
    store.commit("setAboutusTabIndex", menuIndex);
  }
  if (tabIndex == 2) {
    store.commit("setNoticeTabIndex", menuIndex);
  }
  if (tabIndex == 3) {
    store.commit("setOutcomeTabIndex", menuIndex);
  }
  if (tabIndex == 4) {
    //申报管理特殊处理，不能选中第二项，该入口为页面跳转操作
    store.commit("setReportTabIndex", 0);
  }
  activeName.value = tabs[tabIndex]?.name;
  store.commit("setHeaderTabIndex", tabIndex);
  if (path) {
    router.push({
      path: path,
    });
  } else {
    //仅有申报管理第二项配置path为空，特殊处理
    const token = getToken();
    if (!token) {
      openWindow("/index/login");
      return;
    }
    //用户还未提交过认证
    if (!hasCertify()) {
      openWindow("/index/certifyChoose");
      return;
    }
    //用户暂未认证，不可使用该功能
    if (!canUseManage()) {
      ElMessageBox.alert(t("interaction.certifyProcessTips"), t("interaction.notice"), {
        confirmButtonText: t("operate.ok"),
        callback: () => {},
      });
      return;
    }
    //个人中心
    openWindow("/index/manage");
  }
};

const userLogin = () => {
  if (token.value) {
    //已经在管理中心页面时禁止直接跳转该页面
    if (router.currentRoute.value.fullPath.includes("/index/manage")) {
      return;
    }
    //用户还未提交过认证
    if (!hasCertify()) {
      openWindow("/index/certifyChoose");
      return;
    }
    //用户暂未认证成功，只能去认证管理查看
    if (!canUseManage()) {
      store.commit("setManageTabIndex", 1);
      const url = router.resolve({
        path: "/index/manage/certifyManage",
        query: {
          type: getUserInfo()?.authType == 1 ? "organ" : "personal",
        },
      });
      window.open(url.href, "_blank");
      return;
    }
    //个人中心
    openWindow("/index/manage");
  } else {
    openWindow("/index/login");
  }
};
</script>

<style scoped lang="less">
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 62px;
  background: white;
  z-index: 1000;
  .header-log {
    width: 148px;
    height: 66px;
    margin-left: 60px;
  }
  .header-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .tabs-container {
      line-height: 62px;
      .tab-item {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        font-weight: 500;
        font-size: 14px;
        color: #303133;
        margin-left: 40px;
        margin-right: 40px;
        cursor: pointer;
      }
      .tab-item-active {
        color: #2950b8;
        border-bottom: 2px solid #2950b8;
      }
      .tab-item:hover {
        color: #2950b8;
      }
    }
    .header-search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      line-height: 60px;
      background: #f3f3f3;
      border-radius: 50%;
      margin: 17.5px 20px 0px 20px;
      .search-img {
        width: 15px;
        height: 15px;
      }
    }
  }
  .header-user {
    line-height: 60px;
    margin-right: 20px;
    span {
      font-size: 12px;
      padding: 5px 12px;
      border-radius: 30px;
      border: 1px solid #666666;
      cursor: pointer;
    }
  }
  .header-login-user {
    background: #2950b8;
    margin-right: 20px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    align-self: center;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .header-language {
    height: 62px;
    line-height: 62px;
    text-align: center;
    padding: 0px 12px;
    background: #2950b8;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
      margin-left: 5px;
    }
  }
}

/deep/ .el-tabs__nav-wrap::after {
  height: 0px;
}
/deep/ .el-tabs__item {
  padding: 0 40px;
}
/deep/ .el-tabs__header {
  margin: 10px 0 0;
}
</style>
