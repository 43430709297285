import store from "../store"
import {PROJECT_ID_EDIT} from "@/store/store-context";

export const saveToken = (token) => {
  store.commit('setToken', token)
}

export const removeToken = () => {
  store.commit('setToken', '')
}

export const getToken = () => {
  return store.state.token
}

export const saveUserInfo = (info) => {
  store.commit('setUserInfo', info)
}

export const removeUserInfo = () => {
  store.commit('setUserInfo', '')
}

export const getUserInfo = () => {
  return JSON.parse(store.state.userInfo)
}

//临时方案，解决选项卡无法传值问题
export const storageProjectId = (projectId) => {
  sessionStorage.setItem(PROJECT_ID_EDIT, projectId)
}

export const getProjectId = () => {
  return sessionStorage.getItem(PROJECT_ID_EDIT)
}

export const removeProjectId = () => {
  sessionStorage.removeItem(PROJECT_ID_EDIT)
}

export const getLocaleLanguage = () => {
  return store.state.localeLanguage
}

export const setLocaleLanguage = (value) => {
  store.commit('setLocaleLanguage', value)
}