<template>
  <div class="footer-container">
    <img class="footer-logo" src="@/assets/footer/footer_logo_new.png"/>
    <div class="divider"></div>
    <div class="footer-middle-container">
      <div class="middle-first-row">
        版权所有  亚洲文化遗产保护行动  京ICP备12015914号-1 DESIGN BY HLOONG  京公网备案  11010102002390号
      </div>
      <div class="middle-sec-row">
        <img src="@/assets/footer/phone.png"/>
        <div class="row-item">
          <div>Email:zgwbjj@163.com</div>
          <div>地 址:北京市东城区东四北大街219号</div>
        </div>
      </div>
      <div class="middle-third-row">
        版权所有  亚洲文化遗产保护行动  京ICP备12015914号-1 DESIGN BY HLOONG  京公网备案  11010102002390号
      </div>
      <div class="footer-flag">
        <a href="https://beian.miit.gov.cn/" target="_blank">{{$t('home.reserved')}} 京ICP备12015914号-63</a>
        <a style="margin-left: 4px" href="http://www.hloong.cn" target="_blank">DESIGN BY HLOONG</a>
      </div>
    </div>
    <div class="footer-right-container">
      <div class="interact">
        <div>INTERACT WITH US</div>
        <div>与我们互动</div>
        <div class="icons">
          <img src="@/assets/footer/facebook.png"/>
          <img src="@/assets/footer/twitter.png"/>
          <img src="@/assets/footer/weibo.png"/>
        </div>
      </div>
      <img class="scan-code" src="@/assets/footer/scan_code.png"/>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped lang="less">
.footer-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 130px;
  background: #222222;
  color: #999999;
  .footer-logo {
    max-width: 180px;
    max-height: 80px;
  }
  .divider {
    width: 1px;
    height: 110px;
    margin: 0 40px;
    background: #999999;
  }
  .footer-middle-container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    .middle-first-row, .middle-third-row {
      line-height: 36px;
      display: none;
    }
    .middle-sec-row {
      display: flex;
      flex-direction: row;
      display: none;
      img {
        width: 188px;
        height: 42px;
      }
      .row-item {
        line-height: 20px;
        padding: 1px 0 0 20px;
      }
    }
    .footer-flag {
      display: flex;
      align-items: center;
      padding-left: 100px;
      padding-right: 100px;
      color: #ffffff;
      font-size: 14px;
    }
  }
  .footer-right-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 94px;
    padding: 0 0 0 50px;
    visibility: hidden;
    .interact {
      font-size: 12px;
      .icons {
        margin-top: 20px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
      }
    }
    .scan-code {
      width: 74px;
      height: 74px;
      margin-left: 20px;
    }
  }
}
</style>