import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import i18n from "@/i18n";
import '@/style/theme.css'
import '@/style/index.css'
import './permission'

createApp(App).use(store).use(router).use(ElementPlus).use(i18n).mount('#app')