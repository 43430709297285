import router from "@/router";

router.beforeEach((to, from, next) => {
  const title = to.meta.docTitle || to.meta.title
  document.title = title ? title : '亚洲文化遗产保护行动官网'
  next()
})

router.afterEach(() => {

})