import {getUserInfo} from "@/utils/storage-utils";
import i18n from "@/i18n";

const t = i18n.global.t

/**
 * 用户当前是否可使用管理中心功能
 * authStatus 0（待认证）/1（待办）时不可用 2可用 3（认证失败）不可用
 * @returns {boolean}
 */
export const canUseManage = () => {
  const userInfo = getUserInfo()
  const authStatus = userInfo?.authStatus
  if (authStatus == 2) {
    return true
  } else {
    return false
  }
}

//用户是否已经有认证记录
export const hasCertify = () => {
  const userInfo = getUserInfo()
  const authId = userInfo?.authId
  if (authId) {
    return true
  }
  else {
    return false
  }
}

//根据枚举获取展示值
export const getOrganType = (type) => {
  let text = '-'
  switch (type) {
    case 0:
      text = t('selectOptions.privateEnterprise')
      break;
    case 1:
      text = t('selectOptions.socialServiceEnterprise')
      break;
    case 2:
      text = t('selectOptions.socialOrgan')
      break;
  }
  return text;
}

//获取项目类型
export const getProjectType = (type) => {
  let text = '-'
  switch (type) {
    case 0:
      text = t('outcome.tabTitleOne')
      break;
    case 1:
      text = t('outcome.tabTitleTwo')
      break;
    case 2:
      text = t('outcome.tabTitleThree')
      break;
    case 3:
      text = t('outcome.tabTitleFour')
      break;
    case 4:
      text = t('outcome.tabTitleFive')
      break;
    case 5:
      text = t('outcome.tabTitleSix')
      break;
    case 6:
      text = t('outcome.tabTitleSeven')
      break;
    case 7:
      text = t('outcome.tabTitleEight')
      break;
  }
  return text
}

export const getProjectResultStatus = (status) => {
  status = parseInt(status)
  let statusText = '-'
  switch (status) {
    case 0:
      statusText = t('selectOptions.unImplement')
      break;
    case 1:
      statusText = t('selectOptions.finishUnCheck')
      break;
    case 2:
      statusText = t('selectOptions.finishUnDeal')
      break;
    case 3:
      statusText = t('selectOptions.finished')
      break;
    case 4:
      statusText = t('selectOptions.finishReviewFail')
      break;
    case 5:
      statusText = t('selectOptions.extensionUnReview')
      break;
    case 6:
      statusText = t('selectOptions.extensionUnReal')
      break;
    case 7:
      statusText = t('selectOptions.extension')
      break;
    case 8:
      statusText = t('selectOptions.extensionReviewFail')
      break;
  }
  return statusText
}