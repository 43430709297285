<template>
  <el-config-provider :locale="localeEle">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { ElConfigProvider } from 'element-plus';
import zh_CN from 'element-plus/dist/locale/zh-cn.mjs';
import en_US from 'element-plus/dist/locale/en.mjs';
import {ref, watch} from "vue";
import {getLocaleLanguage} from "@/utils/storage-utils";
import {i18nChangeLanguage} from "@wangeditor/editor";
import {useI18n} from "vue-i18n";

const {locale} = useI18n()

watch(
    () => getLocaleLanguage(),
    (newValue) => {
      if (newValue && newValue == 'zh_CN') {
        localeEle.value = zh_CN
        i18nChangeLanguage('zh-CN')
        locale.value = 'zh'
      } else {
        localeEle.value = en_US
        i18nChangeLanguage('en')
        locale.value = 'en'
      }
    }
)

const localeEle = ref(getLocaleLanguage()=='zh_CN' ? zh_CN : en_US)
i18nChangeLanguage(getLocaleLanguage()=='zh_CN' ? 'zh-CN' : 'en')

</script>
