import router from "@/router";

export const openWindow = (path) => {
  try {
    const url = router.resolve({
      path: path,
    })
    window.open(url.href, "_blank")
  } catch (e) {
    console.log(e)
  }
}

