import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Main from '@/views/index'
import i18n from "@/i18n";

const t = i18n.global.t

const routes = [{
    path: '/',
    // redirect: '/index'
    component: () => import('@/views/wh/index'), //维护临时代码，正式上线删除
  },
  {
    path: '/index',
    // redirect: '/index/home',
    redirect: '/', //维护临时代码，正式上线删除
    component: Main,
    children: [{
        path: 'home',
        redirect: '/', //维护临时代码，正式上线删除
        component: () => import('@/views/home/home'),
        name: 'home',
        meta: {
          title: t('homeTabs.home'),
          docTitle: t('documentTitle.home')
        }
      },
      {
        path: 'aboutUs',
        component: () => import('@/views/about-us/about_us'),
        name: 'aboutUs',
        meta: {
          title: t('homeTabs.aboutUs'),
          docTitle: t('documentTitle.aboutUs')
        },
        redirect: '/about/xdjs',
        children: [{
            path: '/about/xdjs',
            component: () => import('@/views/about-us/about_xdjs'),
          },
          {
            path: '/about/lmj',
            component: () => import('@/views/about-us/about_lmj'),
          },
          {
            path: '/about/jijin',
            component: () => import('@/views/about-us/about_jijin'),
          },
          {
            path: '/about/contact',
            component: () => import('@/views/about-us/about_contact'),
          }
        ]
      },
      {
        path: 'notice',
        component: () => import('@/views/notice/notice'),
        name: 'notice',
        meta: {
          title: t('homeTabs.newsNotice'),
          docTitle: t('documentTitle.newsNotice')
        },
        redirect: '/index/notice/new',
        children: [{
            path: '/index/notice/new',
            component: () => import('@/views/notice/new'),
          },
          {
            path: '/index/notice/detail',
            component: () => import('@/views/notice/detail'),
          },
          {
            path: '/index/notice/inform',
            component: () => import('@/views/notice/inform'),
          },
        ],
      },
      {
        path: 'outcome',
        component: () => import('@/views/outcome/outcome'),
        name: 'outcome',
        meta: {
          title: t('homeTabs.outcome'),
          docTitle: t('documentTitle.outcome')
        },
        redirect: '/index/outcome/content',
        children: [{
            path: '/index/outcome/content',
            component: () => import('@/views/outcome/content'),
          },
          {
            path: '/index/outcome/detail',
            component: () => import('@/views/outcome/detail'),
          },
        ]
      },
      {
        path: 'report',
        component: () => import('@/views/report/report'),
        name: 'report',
        meta: {
          title: t('homeTabs.report'),
          docTitle: t('documentTitle.report')
        },
        redirect: '/index/report/guide',
        children: [{
            path: '/index/report/guide',
            component: () => import('@/views/report/guide'),
          },
          {
            path: '/index/report/projectReport',
            component: () => import('@/views/report/project_report'),
          },
        ]
      },
      {
        path: 'login',
        component: () => import('@/views/login/login'),
        name: 'login',
        meta: {
          title: t('routeTitle.loginRegister')
        }
      },
      {
        path: 'protocol',
        component: () => import('@/views/protocol/user'),
        name: 'protocol',
        meta: {
          title: t('loginAgreement.agreement')
        }
      },
      {
        path: 'certifyChoose',
        component: () => import('@/views/certify/certify_choose'),
        name: 'certifyChoose',
        meta: {
          title: t('routeTitle.chooseCertification')
        }
      },
      {
        path: 'certifyContent',
        component: () => import('@/views/certify/certify_content'),
        name: 'certifyContent',
        meta: {
          title: t('routeTitle.reportAgreement')
        }
      },
      {
        path: 'manage',
        component: () => import('@/views/manage/manage'),
        name: 'manage',
        meta: {
          title: t('routeTitle.managerCenter'),
          docTitle: t('documentTitle.manage')
        },
        redirect: '/index/manage/projectManage',
        children: [{
            path: '/index/manage/projectManage',
            component: () => import('@/views/manage/project_manage'),
          },
          {
            path: '/index/manage/certifyManage',
            component: () => import('@/views/manage/certify_manage'),
          },
          {
            path: '/index/manage/downloadFile',
            component: () => import('@/views/manage/download_file'),
          },
          {
            path: '/index/manage/accountManage',
            component: () => import('@/views/manage/account_manage'),
          },
        ]
      }
    ],
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  //路由跳转页面，滚动条回顶部
  if (savedPosition) {
    return savedPosition;
  } else {
    return {
      top: 0
    }
  }
};
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

export default router