import { createStore } from 'vuex'
import {
  HEADER_TAB_INDEX,
  NOTICE_TAB_INDEX,
  OUTCOME_TAB_INDEX,
  REPORT_TAB_INDEX,
  ABOUTUS_TAB_INDEX,
  USER_INFO,
  MANAGE_TAB_INDEX,
  PROJECT_TAB_INDEX,
  TOKEN_AUTH,
  LOCALE_LANGUAGE,
} from "@/store/store-context";

export default createStore({
  state: {
    headerTabIndex: sessionStorage.getItem(HEADER_TAB_INDEX) ?
      sessionStorage.getItem(HEADER_TAB_INDEX) : 0,
    noticeTabIndex: sessionStorage.getItem(NOTICE_TAB_INDEX) ?
      sessionStorage.getItem(NOTICE_TAB_INDEX) : 0,
    outcomeTabIndex: sessionStorage.getItem(OUTCOME_TAB_INDEX) ?
      sessionStorage.getItem(OUTCOME_TAB_INDEX) : 0,
    reportTabIndex: sessionStorage.getItem(REPORT_TAB_INDEX) ?
      sessionStorage.getItem(REPORT_TAB_INDEX) : 0,
    userInfo: sessionStorage.getItem(USER_INFO) ?
      sessionStorage.getItem(USER_INFO) : '{}',
    aboutusTabIndex: sessionStorage.getItem(ABOUTUS_TAB_INDEX) ?
      sessionStorage.getItem(ABOUTUS_TAB_INDEX) : 0,
    manageTabIndex: sessionStorage.getItem(MANAGE_TAB_INDEX) ?
      sessionStorage.getItem(MANAGE_TAB_INDEX) : 0,
    projectTabIndex: sessionStorage.getItem(PROJECT_TAB_INDEX) ?
      sessionStorage.getItem(PROJECT_TAB_INDEX) : 2,
    token: sessionStorage.getItem(TOKEN_AUTH) ?
      sessionStorage.getItem(TOKEN_AUTH) : '',
    localeLanguage: sessionStorage.getItem(LOCALE_LANGUAGE) ?
      sessionStorage.getItem(LOCALE_LANGUAGE) : 'zh_CN', //zh_CN en_US
    flagLoginStatus: "login",
  },
  mutations: {
    setHeaderTabIndex(state, value) {
      state.headerTabIndex = value
      sessionStorage.setItem(HEADER_TAB_INDEX, value)
    },
    setNoticeTabIndex(state, value) {
      state.noticeTabIndex = value
      sessionStorage.setItem(NOTICE_TAB_INDEX, value)
    },
    setOutcomeTabIndex(state, value) {
      state.outcomeTabIndex = value
      sessionStorage.setItem(OUTCOME_TAB_INDEX, value)
    },
    setReportTabIndex(state, value) {
      state.reportTabIndex = value
      sessionStorage.setItem(REPORT_TAB_INDEX, value)
    },
    setAboutusTabIndex(state, value) {
      state.aboutusTabIndex = value
      sessionStorage.setItem(ABOUTUS_TAB_INDEX, value)
    },
    setUserInfo(state, value) {
      if (typeof value == 'object') {
        value = JSON.stringify(value)
      }
      state.userInfo = value
      sessionStorage.setItem(USER_INFO, value)
    },
    setManageTabIndex(state, value) {
      state.manageTabIndex = value
      sessionStorage.setItem(MANAGE_TAB_INDEX, value)
    },
    setProjectTabIndex(state, value) {
      state.projectTabIndex = value
      sessionStorage.setItem(PROJECT_TAB_INDEX, value)
    },
    setToken(state, value) {
      state.token = value
      if (value) {
        sessionStorage.setItem(TOKEN_AUTH, value)
      } else {
        sessionStorage.removeItem(TOKEN_AUTH)
      }
    },
    setLocaleLanguage(state, value) {
      state.localeLanguage = value
      sessionStorage.setItem(LOCALE_LANGUAGE, value)
    },
    setFlagLoginStatus(state, value) {
      state.flagLoginStatus = value
    }
  },
  actions: {

  },
  modules: {}
})